<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcrumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                                <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="2">
                <v-menu ref="modal" v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            solo v-model="date_from" label="Start Period" persistent-hint prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" class="ma-0 pa-0" hide-details=true>
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="date_from" type="month" no-title @input="modal = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="2">
                <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field solo v-model="date_to" label="End Period" persistent-hint prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" class="ma-0 pa-0" hide-details=true></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_to"
                        type="month"
                        no-title
                        @input="modal_to = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="2">
                <v-autocomplete solo clearable v-model="catType" :items="rawMaterial" label="Raw Material" class="ma-0 pa-0" hide-details=true @change="(event) => getCategory(event)"></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="2">
                <v-autocomplete solo clearable v-model="category_id" :items="categories" item-value="category_id" item-text="descr" label="Category" class="ma-0 pa-0" hide-details=true @change="(event) => getProdSpec(event)"></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="2">
                <v-autocomplete solo clearable v-model="article_id" :items="products" item-value="prod_code" item-text="descr" label="Product" class="ma-0 pa-0" hide-details=true></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="2">
                <v-text-field solo clearable v-model="limit" label="Limit" class="ma-0 pa-0" hide-details=true></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="3" md="2">
                <v-btn
                    color="info" elevation="2" large @click="getPullData()">Search</v-btn>
            </v-col>
            <v-col cols="12" class="mt-5">
                <v-card class="pa-md-5">  
                    <h5 class="font-weight: 900; text-darken-3 white-text">Trend</h5>
                    <div id="chartContainer1" class="hahe" style="width: 100%;"></div>
                </v-card>
            </v-col>
            <v-col cols="12" class="mt-5">
                <v-card color="grey lighten-5" class="pa-md-5"> 
                    <div class="d-flex justify-space-between align-center">
                        <h5 class="font-weight: 900; text-darken-3 white-text">Detail</h5>
                        <v-btn small color="#005c37" class="py-5 text-white border-12">
                            <v-icon>mdi-file-excel</v-icon>
                            <download-excel
                                class="text"
                                :fetch="exportExcel"
                                :fields="headersColumn"
                                :before-generate="startDownload"
                                :before-finish="finishDownload">
                                Export Excel
                            </download-excel>
                        </v-btn>
                    </div>
                    <v-data-table
                        dense
                        :headers="headers"
                        :items="items"
                        class="elevation-1 mt-2"
                        :items-per-page="5"
                        fixed-header
                        :divider="true"
                        :light="true">
                        <template v-slot:[`item.qty`]="{ item }">
                            {{ item.qty | noDecimals }}
                        </template>
                        <template v-slot:[`item.amt_item`]="{ item }">
                            {{ item.amt_item | rupiahFormat }}
                        </template>
                        <template v-slot:[`item.tr_date`]="{ item }">
                            {{ item.tr_date | dateFormat }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import _ from "lodash"

  export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcrumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Warehouse',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'Material Usage Trend',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            customers: [],
            catType: '',
            category_id: '',
            article_id: '',
            limit: '',
            categories: [],
            products: [],
            rawMaterial: [
                'RAW', 'NON-RAW'
            ],
            date_from: '',
            modal: false,
            cust_id: '',
            date_to: '',
            // article_id: article_id,
            period: '',
            chartContainer1:[],
            modal_to: false,
            months: [
                'Jan', 'Feb', 'Mar', 'Apr', 'May',
                'Jun', 'Jul', 'Aug', 'Sep',
                'Oct', 'Nov', 'Dec'
            ],
            items: [],
            headers: [
                { text: 'TR ID', align: 'start', sortable: true, value: 'tr_id' },
                { text: 'TR Date', align: 'start', sortable: true, value: 'tr_date' },
                // { text: 'Category', align: 'start', sortable: true, value: 'category' },
                // { text: 'Commodity', align: 'start', sortable: true, value: 'commodity' },
                { text: 'Description', align: 'start', sortable: true, value: 'description' },
                { text: 'Remark', align: 'start', sortable: true, value: 'remark' },
                { text: 'Department', align: 'start', sortable: true, value: 'dept_name' },
                { text: 'Section Name', align: 'start', sortable: true, value: 'sect_name' },
                { text: 'Quantity', align: 'start', sortable: true, value: 'qty' },
                { text: 'Amount', align: 'start', sortable: true, value: 'amt_item' },
                { text: 'UoM', align: 'start', sortable: true, value: 'uom' },
                { text: 'WH', align: 'start', sortable: true, value: 'wh_id' },
                { text: 'User', align: 'start', sortable: true, value: 'user_id' },
            ],
            headersColumn:{
                'TR ID': 'tr_id',
                'TR Date': 'tr_date',
                'Description': 'description',
                'Remark': 'remark',
                'Department': 'dept_name',
                'Section Name': 'sect_name',
                'Quantity': 'qty',
                'Amount': 'amt_item',
                'UoM': 'uom',
                'WH': 'wh_id',
                'User': 'user_id',
            },
        }
    },
    mounted(){
        
    },
    filters: {
        twoDecimals(value) {
            if (!value) return '';
            return parseFloat(value).toFixed(2);
        },
        oneDecimals(value) {
            if (!value) return '';
            return parseFloat(value).toFixed(1);
        },
        noDecimals(value) {
            if (!value) return '';
            return parseFloat(value).toFixed(0);
        },
        rupiahFormat(value) {
            if (!value) return '';
            let formatter = new Intl.NumberFormat('id-ID', {
                style: 'decimal',
                minimumFractionDigits: 0
            });
            return formatter.format(value);
        },
        dateFormat(value) {
            if (!value) return '';
            let date = new Date(value);
            return date.toISOString().substr(0, 10);
        }
    },
    methods:{
        getCategory(event) {
            axios.post(`${process.env.VUE_APP_URL}/api/sr/getCatType`, {
                catType: this.catType
            }, {
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
            })
            .then(res => {
                this.categories = res.data.data;
            });
        },
        getProdSpec(event) {
            axios.get(`${process.env.VUE_APP_URL}/v2/master/sr/prod_spec?category_id=${this.category_id ? this.category_id : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.products = res.data;
            });
        },
        async getPullData(){
            this.loading = true;
            this.items = [];
            if (this.rawMaterial === '' || this.date_from === '' || this.date_to === '' || this.limit === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Fill Raw Material, Start Date, End Date & Limit',
                    visible: true
                };
                this.loading = false;
                return;
            }

            const start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : '';
            const end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 8).replace(/-/g,"") : '';

            try {
                this.$store.dispatch('setOverlay', true)
                const response = await axios.post(`${process.env.VUE_APP_URL}/api/sr/material_usage_trend`, {
                    'catType': this.catType,
                    'category_id': this.category_id,
                    'article_id': this.article_id,
                    'limit': this.limit,
                    'office_id': "SR",
                    start: start_date,
                    end: end_date
                }, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                });

                const groupedData = {};
                // response.data.data.forEach(item => {
                //     if (!groupedData[item.period]) {
                //         groupedData[item.period] = [];
                //     }
                //     groupedData[item.period].push({
                //         description: item.description,
                //         total_qty: parseFloat(item.total_qty) // Ensure the value is a number
                //     });
                // });
                response.data.data.forEach(item => {
                    if (!groupedData[item.description]) {
                        groupedData[item.description] = [];
                    }
                    groupedData[item.description].push({
                        description: item.period,
                        total_qty: parseFloat(item.total_qty) ,
                        article_id: item.article_id,
                        period: item.period,
                    });
                });
                console.log("groupedData ", groupedData)
                this.fillMissingLabelsByYear(groupedData, start_date, end_date)

                // const dataPoints1 = Object.keys(groupedData).map(period => {
                //     const sortedDescriptions = groupedData[period].sort((a, b) => b.period - a.period).slice(0, this.limit);
                //     return {
                //         type: "line",
                //         showInLegend: true,
                //         name: period,
                //         visible: false,
                //         dataPoints: sortedDescriptions.map(item => ({
                //             label: item.description,
                //             y: item.total_qty
                //         }))
                //     }
                // });

                const dataPoints1 = Object.keys(groupedData).map(description => {
                    const sortedPeriods = groupedData[description].sort((b, a) => b.period - a.period).slice(0, this.limit);
                    return {
                        type: "line",
                        showInLegend: true,
                        name: description,
                        visible: false,
                        dataPoints: sortedPeriods.map(item => ({
                            label: item.description,
                            y: item.total_qty,
                            click: (e) => {
                                console.log("Article ID: ", item.article_id);
                                console.log("Period: ", item.period);
                                this.handleDataPointClick(item.article_id, item.period);
                            }
                        }))
                    };
                });

                console.log('dataPoints1:', dataPoints1);
                this.$store.dispatch('setOverlay', false)
                this.renderChart1(dataPoints1);

            } catch (error) {
                console.error('Error fetching data:', error);
                this.snackbar = {
                    color: "blue",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "No Data",
                    text: 'Search another Product',
                    visible: true
                };
            }

            this.loading = false;
        },
        generateLabels(startLabel, endLabel) {
            const labels = [];
            let [startYear, startMonth] = [parseInt(startLabel.substring(0, 4), 10), parseInt(startLabel.substring(4, 6), 10)];
            const [endYear, endMonth] = [parseInt(endLabel.substring(0, 4), 10), parseInt(endLabel.substring(4, 6), 10)];

            while (startYear < endYear || (startYear === endYear && startMonth <= endMonth)) {
                const label = `${startYear}${startMonth.toString().padStart(2, '0')}`;
                labels.push(label);

                startMonth++;
                if (startMonth > 12) {
                    startMonth = 1;
                    startYear++;
                }
            }

            return labels;
        },
        fillMissingLabelsByYear(data, startLabel, endLabel) {
            _.forEach(data, (dataset, key) => {
                // Extract year from the dataset key
                const datasetYear = parseInt(key.split(" ")[1], 10);

                // Generate labels for the past 12 months
                const labels = this.generateLabels(startLabel, endLabel);
                
                // Get existing labels in the dataset
                const existingLabels = _.map(dataset, 'description');
                
                // Determine which labels are missing
                const missingLabels = _.difference(labels, existingLabels);

                // Add missing labels to the dataset with a value of null
                _.forEach(missingLabels, (label) => {
                    dataset.push({ description: label, total_qty: 0 });
                });

                // Sort dataset by label to maintain chronological order
                dataset.sort((a, b) => a.description.localeCompare(b.description));
            });

            return data;
        },
        renderChart1(dataPoints1) {
            var chart = new CanvasJS.Chart("chartContainer1", {
                theme: "light2",
                title: {
                    text: "Material Usage Trend"
                },
                axisY: {
                    title: "Total Qty"
                },
                axisX: {
                    title: "Period"
                },
                legend: {
                    cursor: "pointer",
                    itemclick: function (e) {
                        if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                            e.dataSeries.visible = false;
                        } else {
                            e.dataSeries.visible = true;
                        }
                        chart.render();
                    }
                },
                data: dataPoints1
            });

            console.log('Rendering chart with dataPoints:', dataPoints1);
            chart.render();
        },
        async handleDataPointClick(article_id, period) {
            this.article_id = article_id
            this.period = period
            this.$store.dispatch('setOverlay', true)
            await axios.post(`${process.env.VUE_APP_URL}/api/sr/material_usage_trend/DailyDetail`, {
                article_id: this.article_id,
                period: this.period,
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.items  = res.data.data
                this.$store.dispatch('setOverlay', false)
            })
            console.log('Detail data:', items.data);
        },
        async exportExcel(article_id, period){
            const respData = await axios.post(`${process.env.VUE_APP_URL}/api/sr/material_usage_trend/DailyDetail`, {
                article_id: this.article_id,
                period: this.period,
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            if (respData.status === 200) {
                return respData.data.data
            }
            // .then(res => {
            //     this.items  = res.data.data
            //     this.$store.dispatch('setOverlay', false)
            // })
            console.log('Export data:', respData.data);
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    }
  }
</script>

<style scoped>
  .crumb-item {
    cursor: pointer;
  }
  .hahe {
    height: 370px;
    width: 100%;
  }
  .bg_card {
    background: linear-gradient(45deg, #1e88e5, #3949ab);
  }
</style>
